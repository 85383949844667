<!--评价功能-->
<template>
    <div class="evaluatiton">
        <div class="h3">评价</div>
        <div class="main">
            <div class="row his-rate">
                <div class="inner-box">
                    <div class="i-title">综合评分：</div>
                    <el-rate class="rate_star" v-model="value" disabled show-score text-color="#ff9900" score-template="{value}分">
                    </el-rate>
                </div>
                <div class="inner-box">
                    <div class="i-title">好评度：</div>
                    <div class="rate_per">
                        {{Math.round(evaluateStar/5*100) }}%
                    </div>
                </div>
                <div class="inner-box">
                    <div class="i-title">大家都写到：</div>
                    <div class="hotKey">
                        <span class="hotkey-item" :class="{act:commentArr.includes(key)}" v-for="(item,key) in keyList" :key="key+'hotKey'" @click="hanleKeyIn(key)">
                            {{key}}(<span style="color:#409EFF">{{item||0}}</span>)
                        </span>
                    </div>
                    
                </div>
            </div>
            <div class="markRate">
                <div class="m-title">我来评价:</div>
                <div class="mr-box">
                    <el-rate class="rate-m" v-model="value1"  show-score text-color="#ff9900" score-template="{value}分"></el-rate>
                    <div class="comment-form">
                        <el-input  v-model="comment" readonly placeholder="请选择关键词条" maxlength="320" style="width: calc(100% - 140px)"></el-input>
                        <el-button  type="warning" v-if="token" @click="submit" style="margin-left:20px">发布</el-button>
                        <el-button  type="warning" v-else @click="submit" style="margin-left:10px">登录并发布</el-button>
                    </div>
                </div>
            </div>
            <div class="history-rote">
                <div class="comment-title">历史评价({{commentList.total}})</div>
                <div class="list" v-if="commentList.list&&commentList.list.length>0">
                    <div class="comment-item" v-for="item in commentList.list" :key="item.id">
                        <div class="clearfix">
                            <el-rate class="rate_his" v-model="item.evaluateStar" disabled show-score text-color="#ff9900" score-template="{value}分"></el-rate>
                        </div>
                        <div class="content">{{item.evaluation}}</div>
                        <div class="userInfo">
                            <span><i class="el-icon-user"></i> {{item.createdUserName||'匿名用户'}}</span>
                            <span style="margin-left:80px"><i class="el-icon-time"></i>{{item.createdTime}}</span>
                            <span style="margin-left:80px;cursor:pointer" @click="deleteCommet(item.id)" v-if="id == item.createdUserId">删除</span>
                        </div>
                    </div>
                    <div style="text-align:right;margin-top:8px">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="hadlePage"
                            :total="commentList.total">
                        </el-pagination>
                    </div>
                </div>
                <div v-else class="empty">
                    暂无评论
                </div>
            </div>
            
        </div>  
    </div> 
</template>

<script>
import {evaluationAdd,evaluationGetPage,deleteEvaluation,StatKeyword} from '@/api/evaluation'
import {mapState} from 'vuex'
export default {
    data(){
        return{
            value:4.5,
            value1:4,
            comment:'',
            commentArr:[],
            commentList:{},
            params:{filter:{},pageSize:10,currentPage:1},
        }
    },
    computed:{
		...mapState({
			token:state=>state.token,
			id: state=>state.id,
		})
	},
    props:{
		objectId:String,
		tableName:String,
        keyList:Object,
        count:Number,
        evaluateStar:Number,
	},
    watch:{
		token(a){
			if(a){
				this.submit();
			}
		},
        evaluateStar(a){
            this.value = this.evaluateStar;
        }
	},
    mounted(){
        this.value = this.evaluateStar;
        this.params.filter.objectId = this.objectId
		this.getList();
    },
    methods:{
        hanleKeyIn(k){
            if(!this.commentArr.includes(k)){
                this.commentArr.push(k);
                this.comment = this.commentArr.join()
            }else{
                let index = this.commentArr.findIndex(item=>item === k);
                this.commentArr.splice(index,1)
                this.comment = this.commentArr.join()
            }
        },
        submit(){
			if(!(this.comment&&this.comment.trim())){
				this.$message({
					message:'评价不可为空',
					type:'error'
				})
				return false
			}
			if(this.token){
				const data = {
                    evaluateStar:this.value1,
					evaluation:this.comment,
					objectId:this.objectId,
					tableName:this.tableName,
				}
				evaluationAdd(data).then(res=>{
					if(res.data.code === 1){
						this.$message({
							message:'发布成功',
							type:'success'
						})
						this.comment = ''
                        this.commentArr = []
						this.$emit('sum')
						this.getList()
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			}else{
				this.$bus.$emit('showLogin')
			}
		},
        getList(){
			evaluationGetPage(this.params).then(res=>{
				this.commentList = res.data.data
			})
            this.getHotKeyCount();
		},
        hadlePage(page){
			this.params.currentPage = page;
			this.getList()
		},
        deleteCommet(id){
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				deleteEvaluation(id).then(res=>{
					if(res.data.code == 1){
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
						this.$emit('sum')
					}else{
						this.$message({
							message:res.data.msg,
							type:'error'
						})
					}
				})
				
			}).catch(() => {         
			});
		},
        getHotKeyCount(){
            for(let key in this.keyList){
                StatKeyword({objectId:this.objectId,keyword:key}).then(res=>{
                    this.$set(this.keyList,key,res.data.data)
                })
            }
        },

    }
}
</script>

<style scoped>
.hotkey-item{
    margin-right: 30px;
    cursor: pointer;
}
.hotkey-item.act{
    color: #4e81ee;

}
.hotKey{
    line-height: 24px;
    color: #999;
}
.rate_his{
    float: right;
}
.empty{
	line-height: 80px;
	color:#ccc;
	text-align: center;
}
.comment-item{
	padding: 15px 0 5px 0;
	border-bottom: 1px solid #eee;
}
.content{
	color: #555;
	font-size: 16px;
	line-height: 24px;
}
.userInfo{
	font-size: 15px;
	color: #999;
	text-align: right;
	line-height: 30px;
}
.list{
	padding: 10px;
}
.comment-title{
    /* line-height: 30px; */
    border-bottom: 1px solid #f3c581;
    padding-left: 4px;
    padding-bottom: 5px;
}
.history-rote{
    padding: 6px 16px;
}
.rate-m{
    padding: 6px 0;
}
.comment-form {
    margin-top:20px;
}
.mr-box{
    padding: 10px;
}
.m-title{
    line-height: 30px;
}
.markRate{
    background: #f9f9f9;
    padding:0 16px 16px;
}
.his-rate{
    padding: 16px;
}
.rate_star{
    /* line-height: 40px; */
    padding: 11px 0;
}
.rate_per{
    color:#e4393c;
    font-size: 30px;
    line-height: 40px;
}
.i-title{
    line-height: 30px;
}
.inner-box{
    width: 30%;
}
.inner-box:nth-child(2){
    width: 20%;
}
.inner-box:last-child{
    width: 50%;
}
.h3{
    line-height: 40px;
    background: #f7f7f7;
    border: 1px solid #ccc;
    font-weight: 600;
    padding-left: 16px;
    font-size: 15px;
    color: #666;
}
.main{
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
</style>
<!-- 特色美食详情页-->
<template>
    <div class="bestway">
        <div class="bestway-main">
            <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/xiangninghui'}">乡宁荟</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/xiangninghui/shopping'}">乡宁购</el-breadcrumb-item>
                    <el-breadcrumb-item >{{pageData.name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="detail">
                <el-row :gutter="20">
                    <el-col :span="18">
                        <div class="d-f1 clearfix mt-20">
                            <div class="fl">
                                <el-carousel trigger="click" height="420px" style="width:560px" :autoplay="false" arrow="never">
                                    <el-carousel-item v-for="item in pageData.photoUrls.split(',')" :key="item+'url'">
                                        <div class="f1-pic fl">
                                            <el-image style="width:100%;height:100%" :src="item"></el-image>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>   
                            </div>
                            <div class="f1-info fr">
                                <div class="f1-h4">{{ pageData.name }}</div>
                                <div class="face-intro row">
                                    <el-rate class="rate_star" v-model="pageData.evaluateStar" disabled show-score text-color="#ff9900" score-template="{value}分"></el-rate>
                                    <span>{{pageData.evaluateNumber}} 条评价</span>
                                </div>
                                <div class="pay">
                                    <h3>参考价格:</h3>
                                    <span class="price">{{pageData.price}}元/</span><span class="unit">{{pageData.unit}}（{{pageData.specifications}}）</span>
                                </div>
                                <div class="f1-text">
                                    <h3>简介：</h3>
                                    <div class="text-info">{{ pageData.introduction }}</div>
                                </div>
                            </div>                         
                        </div>
                        <!-- <div class="d-f2-title row mt-20">
                            <div class="act">商品详情</div>
                            <span>|</span>
                            <div>累计评价</div>
                        </div> -->
                        <!-- <div class="d-f3 mt-20">
                            <table class="d-f3-tabel">
                                <tr>
                                    <td>品牌名称：章鸭子</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>产品参数：</td>
                                </tr>
                                <tr>
                                    <td>生产许可证编号：SC10443072500519</td>
                                    <td>产品标准号：GB2714 </td>
                                    <td>厂名：湖南桃花鸭食品科技有限公司</td>
                                </tr>
                                <tr>
                                    <td>厂址：青海省西宁市东城区.</td>
                                    <td>厂家联系方式：4000-520-800  </td>
                                    <td>储藏方法：阴凉干燥处</td>
                                </tr>
                                <tr>
                                    <td>保质期：240 天</td>
                                    <td> 品牌: 章鸭子</td>
                                    <td>系列: 散称套餐</td>
                                </tr>
                                <tr>
                                    <td>食品工艺: 卤味</td>
                                    <td>产地: 中国大陆 </td>
                                    <td>省份: 青海省 </td>
                                </tr>
                                <tr>
                                    <td>城市: 西宁市</td>
                                    <td>是否为有机食品: 否 </td>
                                    <td>包装方式: 包装 </td>
                                </tr>
                                <tr>
                                    <td>肉类产品: 其他/other</td>
                                    <td>净含量: 1000g</td>
                                </tr>
                            </table>
                        </div> -->
                        <div class="d-f4 mt-20">
                            <!--评价功能-->
                            <evaluatiton :objectId="$route.query.id" :tableName="'t_s_product'" :evaluateStar="pageData.evaluateStar" :keyList="keyList" @sum="getPageData"></evaluatiton>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="m-r">
                            <div class="tj-h3">猜你喜欢</div>
                            <div class="tuijian-item" v-for="item in pageDataList" :key="item.id + '12'">
                                 <router-link :to="`redirect?path=fooddetail&id=${item.id}`">
                                <div class="tj-pic">
                                    <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                </div>
                                <div class="tj-title ellipsis">{{ item.name }}</div>
                                 </router-link>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import{GetFoodFarm,GetFoodPage} from '@/api/home'
import evaluatiton from '../../../components/xiangninghui/evaluatiton.vue';
export default {
    components:{
        evaluatiton
    },
    data(){
        return{
            pageData:{name:'',photoUrls:''},
            pageDataList: {},
            params: {
                currentPage: 1,
                pageSize: 6,
            },
            keyList:{
                '好吃':0,
                '价格实惠':0,
                '色香味俱全':0,
                '鲜香可口':0,
                '制作精细':0,
                '量足味美':0,
            },
        }
    },
    methods:{
        getPageData(){
            GetFoodFarm(this.$route.query.id).then(res=>{
                this.pageData=res.data.data;
            })
        },
        getPagaList() {
        GetFoodPage(this.params).then((res) => {
            const data = res.data.data.list;
            data.forEach((element) => {
            element.photoUrls = element.photoUrls.split(",");
        });
        this.pageDataList = data;
      });
    },
    },
    mounted(){
        this.getPageData();
        this.getPagaList();
    }
}

</script>
<style scoped>
.text-info{
  line-height: 24px;
  font-size: 15px;
  color: #777;
  text-indent: 32px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}
.pay .price{
  font-size: 20px;
  color: #ff9600 !important;
}
.pay{
  line-height: 30px;
  font-size: 15px;
}
.pay>h3,.pay>div{
  display: inline-block;
}
.fl-item{
  line-height: 30px;
  font-size: 15px;
  display: flex;
}
.unit{
  margin-left: 5px;
  vertical-align: bottom;
  /* line-height: 36px; */
  color: #b18d5a;
}
.fl-item>span:not(.unit){
  color: #777;
  margin-left: 20px;
}
.rate_star{
  margin-right: 30px;
}
.face-intro{
  margin: 20px 0;
  line-height: 18px;
  font-size: 14px;
}
.face-intro span:last-child{
  color: #0b58c2;
}
.tj-title{
    font-size: 18px;
    text-align: center;
    line-height: 28px;
}
.tj-pic{
    height: 150px;
}
.tj-h3{
    font-size: 22px;
    font-weight: 600;
    padding: 5px 0;
}
.m-r{
    border: 1px solid #777;
    padding: 15px;
}
.pj-date{
    color: #999;
    font-size: 14px;
}
.pj-or span{
    color: #999;
}
.pj-or{
    text-align: right;
}
.pj-item{
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #777;
}
.shaixuan >>> .el-radio__input.is-checked .el-radio__inner{
    border-color: #141414;
    background: #141414;
}
.shaixuan >>> .el-radio__input.is-checked+.el-radio__label {
    color: #141414;
}
.shaixuan{
    border: 1px solid #ddd;
    padding: 10px;
    background: #eee;
}
.card-list li.no{
    color: #0b58c2;
    border-color: #0b58c2;
}
.card-list li{
    line-height: 40px;
    padding: 0 20px;
    margin-left: 20px;
    border: 1px solid #c4181f;
    color: #c4181f;
    font-size: 18px;
    margin-top: 15px;
}
.card-list{
    padding: 0 20px;
}
.card-info>div{
    font-size: 20px;
    width: 20px;
    color: #999;
}
.card-info{
    padding-left: 30px;
    max-width: calc(100% - 160px);
}
.card-left>div:nth-child(3){
    line-height: 30px;
    font-size: 30px;
    color: #ff9600;
}
.card-left>div:nth-child(2){
    line-height: 50px;
    font-size: 40px;
    color: #c4181f;
}
.card-left>div:nth-child(1){
    line-height: 30px;
    font-size: 18px;
    color: #999;
}
.card-left{
    width: 180px;
    border-right: 1px solid #ddd;
    text-align: center;
}
.d-f4-card{
    padding: 20px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.d-f4-title{
    line-height: 40px;
    font-size: 20px;
    color: #fff;
    background: #c4181f;
    padding: 0 8px;
}
.d-f3-tabel td{
    height: 32px;
}
.d-f3-tabel{
    width: 100%;
    font-size: 16px;
}
.d-f2-title div.act{
    color: #c4181f;
    border-bottom: 2px solid #c4181f;
}
.d-f2-title div{
    margin: 0 20px;
}
.d-f2-title{
    /* height: 40px; */
    line-height: 40px;
    border: 1px solid #777;
    font-size: 22px;
}
.f1-text{
    line-height: 30px;
    font-size: 18px;
}
.f1-h4{
    font-size: 22px;
    line-height: 50px;
    margin-top: 0px;
    font-weight: 600;
}
.f1-info{
    height: 420px;
    width: calc(100% - 560px);
    padding:10px 25px
}
.f1-pic{
    width: 560px;
    height: 420px;
}
.detail{
    margin-top: 50px;
    font-family: '微软雅黑';
}
.bestway-main{
    width: 1200px;
    margin: 0 auto;
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>
import axios from '@/utils/axios';
export function evaluationAdd(data) {
    /**
     * 新增评价
     **/
    return axios({
        method: 'post',
        url: "/api/Village/Evaluation/Add",
        data,
    })
}
export function evaluationGetPage(data) {
    /**
     * 分页获取
     **/
    return axios({
        method: 'post',
        url: "/api/Village/Evaluation/GetPage",
        data,
    })
}
export function deleteEvaluation(id) {
    /**
     * 删除
     **/
    return axios({
        method: 'delete',
        url: "/api/Village/Evaluation/SoftDelete",
        params:{id},
    })
}
export function StatKeyword(data) {
    /**
     * 获取关键字统计
     **/
    return axios({
        method: 'post',
        url: "/api/Village/Evaluation/StatKeyword",
        data:data,
    })
}